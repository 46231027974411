import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  CircularProgress,
  Pagination,
  useMediaQuery,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const Doctors = () => {
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const doctorsPerPage = 16;
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // State variables for filters
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedHospital, setSelectedHospital] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [cities, setCities] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [departments, setDepartments] = useState([]);

  // Access query parameters from the URL
  const location = useLocation();

  useEffect(() => {
    const fetchDoctors = async () => {
      const querySnapshot = await getDocs(collection(db, "doctors"));
      const doctorsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setDoctors(doctorsData);

      // Extract unique cities, hospitals, and departments
      const uniqueCities = [
        ...new Set(
          doctorsData.map((doctor) => capitalizeFirstLetter(doctor.city))
        ),
      ];
      const uniqueHospitals = [
        ...new Set(
          doctorsData.map((doctor) => capitalizeFirstLetter(doctor.hospital))
        ),
      ];
      const uniqueDepartments = [
        ...new Set(
          doctorsData.map((doctor) => capitalizeFirstLetter(doctor.department))
        ),
      ];

      setCities(uniqueCities);
      setHospitals(uniqueHospitals);
      setDepartments(uniqueDepartments);

      setLoading(false);
    };
    fetchDoctors();
  }, []);

  useEffect(() => {
    // Only proceed if departments have been loaded
    if (departments.length > 0) {
      // Parse query parameters
      const params = new URLSearchParams(location.search);
      const departmentParam = params.get("department");
      const cityParam = params.get("city");
      const hospitalParam = params.get("hospital");

      if (departmentParam) {
        setSelectedDepartment(capitalizeFirstLetter(departmentParam));
      }
      if (cityParam) {
        setSelectedCity(capitalizeFirstLetter(cityParam));
      }
      if (hospitalParam) {
        setSelectedHospital(capitalizeFirstLetter(hospitalParam));
      }
    }
  }, [location.search, departments]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const capitalizeFirstLetter = (text) => {
    return text
      ? text
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      : "";
  };

  // Filter doctors based on selected filters
  const filteredDoctors = doctors.filter((doctor) => {
    return (
      (selectedCity === "" ||
        capitalizeFirstLetter(doctor.city) === selectedCity) &&
      (selectedHospital === "" ||
        capitalizeFirstLetter(doctor.hospital) === selectedHospital) &&
      (selectedDepartment === "" ||
        capitalizeFirstLetter(doctor.department) === selectedDepartment)
    );
  });

  const indexOfLastDoctor = currentPage * doctorsPerPage;
  const indexOfFirstDoctor = indexOfLastDoctor - doctorsPerPage;
  const currentDoctors = filteredDoctors.slice(
    indexOfFirstDoctor,
    indexOfLastDoctor
  );

  if (loading) {
    return (
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container
      sx={{
        mt: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontWeight: 700,
          fontFamily: "'Playfair Display', serif",
          color: "#333",
          mb: 4,
          textAlign: "center",
          letterSpacing: "1px",
          paddingBottom: "10px",
          borderBottom: "2px solid #007bff",
          fontSize: isMobile ? "1.5rem" : "2.5rem",
        }}
      >
        Doctors
      </Typography>

      {/* Filters */}
      <Grid container spacing={2} justifyContent="center" sx={{ mb: 4 }}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="city-select-label">City</InputLabel>
            <Select
              labelId="city-select-label"
              id="city-select"
              value={selectedCity}
              onChange={(e) => {
                setSelectedCity(e.target.value);
                setCurrentPage(1);
              }}
              label="City"
            >
              <MenuItem value="">
                <em>All Cities</em>
              </MenuItem>
              {cities.map((city) => (
                <MenuItem key={city} value={city}>
                  {city}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="hospital-select-label">Hospital</InputLabel>
            <Select
              labelId="hospital-select-label"
              id="hospital-select"
              value={selectedHospital}
              onChange={(e) => {
                setSelectedHospital(e.target.value);
                setCurrentPage(1);
              }}
              label="Hospital"
            >
              <MenuItem value="">
                <em>All Hospitals</em>
              </MenuItem>
              {hospitals.map((hospital) => (
                <MenuItem key={hospital} value={hospital}>
                  {hospital}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="department-select-label">Department</InputLabel>
            <Select
              labelId="department-select-label"
              id="department-select"
              value={selectedDepartment}
              onChange={(e) => {
                setSelectedDepartment(e.target.value);
                setCurrentPage(1);
              }}
              label="Department"
            >
              <MenuItem value="">
                <em>All Departments</em>
              </MenuItem>
              {departments.map((department) => (
                <MenuItem key={department} value={department}>
                  {department}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Doctors List */}
      <Grid container spacing={isMobile ? 2 : 4} justifyContent="center">
        {currentDoctors.map((doctor) => (
          <Grid item xs={12} key={doctor.id}>
            <Card
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                width: isMobile ? "100%" : "800px",
                padding: 2,
                alignItems: "center",
                borderRadius: "12px",
                backgroundColor: "#f0f4f8",
                backgroundImage: "linear-gradient(135deg, #e0f7fa, #f0f4f8)",
                boxShadow:
                  "0 4px 8px rgba(0,0,0,0.1), 0 8px 16px rgba(0,0,0,0.1)",
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "scale(1.02)",
                  boxShadow:
                    "0 8px 16px rgba(0,0,0,0.2), 0 12px 24px rgba(0,0,0,0.2)",
                },
              }}
            >
              <CardMedia
                component="img"
                sx={{
                  width: isMobile ? 140 : 140,
                  height: isMobile ? 140 : 140,
                  borderRadius: "8px",
                  marginRight: isMobile ? 0 : 2,
                  objectFit: "cover",
                  marginBottom: isMobile ? "1rem" : 0,
                }}
                image={doctor.image || "/images/doctors/default.png"}
                alt={doctor.doctorName}
              />
              <CardContent
                sx={{ flex: 1, textAlign: isMobile ? "center" : "left" }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    fontFamily: "'Poppins', sans-serif",
                    color: "#333",
                    fontSize: isMobile ? "1rem" : "1.25rem",
                  }}
                >
                  Dr. {capitalizeFirstLetter(doctor.doctorName)}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  {capitalizeFirstLetter(doctor.city)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Experience: {doctor.experience} years of experience
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {capitalizeFirstLetter(doctor.department)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Works At: {capitalizeFirstLetter(doctor.hospital)}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    marginTop: "1rem",
                    justifyContent: isMobile ? "center" : "flex-start",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      fontWeight: 600,
                      borderRadius: "20px",
                      backgroundColor: "#25D366",
                      color: "#fff",
                      fontSize: "0.875rem",
                      padding: "6px 12px",
                      "&:hover": {
                        backgroundColor: "#1DA851",
                      },
                    }}
                    href={`https://api.whatsapp.com/send?phone=919454674622&text=Hello HelalHealthCare, I would like to book an appointment.`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Book An Appointment
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontWeight: 600,
                      borderRadius: "20px",
                      backgroundColor: "#007bff",
                      fontSize: "0.875rem",
                      padding: "6px 12px",
                      "&:hover": {
                        backgroundColor: "#0056b3",
                      },
                    }}
                    onClick={() => navigate(`/doctor/${doctor.id}`)}
                  >
                    Read More
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Pagination */}
      <Pagination
        count={Math.ceil(filteredDoctors.length / doctorsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        sx={{
          mt: 4,
          "& .MuiPaginationItem-root": {
            borderRadius: "50%",
            backgroundColor: "#f0f4f8",
            color: "#007bff",
            "&.Mui-selected": {
              backgroundColor: "#007bff",
              color: "#fff",
            },
            "&:hover": {
              backgroundColor: "#e0f7fa",
            },
          },
        }}
      />
    </Container>
  );
};

export default Doctors;
