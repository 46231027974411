import { Icon } from "@iconify/react";
import React from "react";

export default function ContactInfoWidget() {
  return (
    <ul className="cs_contact_widget" style={{ listStyle: "none", padding: 0 }}>
      {/* Address */}
      <li style={{ marginBottom: "15px" }}>
        <i className="cs_accent_bg" style={{ marginRight: "10px" }}>
          <Icon icon="ep:location" />
        </i>
        <a
          href="https://www.google.com/maps?q=R-42,+3rd+Floor,+Opp.+Hira+Sweet,+Main+Vikas+Marg+Shakarpur,+Near+Metro+Pillar+No.46,+Delhi-110092"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "inherit", textDecoration: "none" }}
        >
          R-42, 3rd Floor, Opp. Hira Sweet, Main Vikas Marg Shakarpur, Near
          Metro Pillar No.46, Delhi-110092
        </a>
      </li>

      {/* Phone */}
      <li style={{ marginBottom: "15px" }}>
        <i className="cs_accent_bg" style={{ marginRight: "10px" }}>
          <Icon icon="fluent:call-24-regular" />
        </i>
        <a
          href="tel:+919454674622"
          style={{ color: "inherit", textDecoration: "none" }}
        >
          +91-9454674622, &nbsp;
        </a>
        <br />
        <a
          href="tel:+911145871974"
          style={{ color: "inherit", textDecoration: "none" }}
        >
          01145871974
        </a>
      </li>

      {/* Email */}
      <li style={{ marginBottom: "15px" }}>
        <i className="cs_accent_bg" style={{ marginRight: "10px" }}>
          <Icon icon="bi:envelope" />
        </i>
        <a
          href="mailto:helalhealthcare.co@gmail.com"
          style={{ color: "inherit", textDecoration: "none" }}
        >
          helalhealthcare.co@gmail.com, &nbsp;
        </a>
        <br />
        <a
          href="mailto:info@helalhealthcare.com"
          style={{ color: "inherit", textDecoration: "none" }}
        >
          info@helalhealthcare.com
        </a>
      </li>
    </ul>
  );
}
