import React from "react";
import {
  Container,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Box,
  Grid,
  Button,
  Divider,
} from "@mui/material";

const HospitalPageTemplate = ({
  image,
  name,
  location,
  description,
  specialServices,
  teamAndSpecialties,
  infrastructure,
  facilities,
  address,
  locationDetails,
}) => {
  return (
    <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
      {/* Hospital Card with Image and Details */}
      <Card
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
          borderRadius: 4,
          overflow: "hidden",
          backgroundColor: "#f9f9f9",
        }}
      >
        <CardMedia
          component="img"
          image={image || "/images/hospitalImages/default.png"} // Fallback image
          alt={name}
          sx={{
            width: { xs: "100%", md: "50%" },
            objectFit: "cover",
            height: "auto",
          }}
        />
        <CardContent
          sx={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            p: 4,
            backgroundColor: "#fff",
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{
              fontWeight: "bold",
              color: "#2c3e50",
              fontFamily: "'Poppins', sans-serif",
            }}
          >
            {name}
          </Typography>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            sx={{
              mb: 2,
              fontFamily: "'Poppins', sans-serif",
              fontSize: "1.1rem",
            }}
          >
            {location}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "'Roboto', sans-serif",
              color: "#555",
              lineHeight: 1.6,
              fontSize: "1rem",
            }}
          >
            {description}
          </Typography>
        </CardContent>
      </Card>

      {/* Special Services */}
      {specialServices && (
        <Box
          mt={5}
          sx={{ padding: 3, backgroundColor: "#eef2f7", borderRadius: 2 }}
        >
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontWeight: "bold", color: "#2c3e50" }}
          >
            Special Services
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Typography
            variant="body2"
            sx={{ lineHeight: 1.8, fontSize: "1rem", color: "#666" }}
          >
            {specialServices}
          </Typography>
        </Box>
      )}

      {/* Team and Specialties */}
      {teamAndSpecialties && (
        <Box
          mt={5}
          sx={{ padding: 3, backgroundColor: "#fff", borderRadius: 2 }}
        >
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontWeight: "bold", color: "#2c3e50" }}
          >
            Team and Specialties
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Typography
            variant="body2"
            sx={{ lineHeight: 1.8, fontSize: "1rem", color: "#666" }}
          >
            {teamAndSpecialties}
          </Typography>
        </Box>
      )}

      {/* Infrastructure */}
      {infrastructure && (
        <Box
          mt={5}
          sx={{ padding: 3, backgroundColor: "#eef2f7", borderRadius: 2 }}
        >
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontWeight: "bold", color: "#2c3e50" }}
          >
            Infrastructure
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Typography
            variant="body2"
            sx={{ lineHeight: 1.8, fontSize: "1rem", color: "#666" }}
          >
            {infrastructure}
          </Typography>
        </Box>
      )}

      {/* Facilities */}
      {facilities && (
        <Box
          mt={5}
          sx={{ padding: 3, backgroundColor: "#fff", borderRadius: 2 }}
        >
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontWeight: "bold", color: "#2c3e50" }}
          >
            Facilities
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Typography
            variant="body2"
            sx={{ lineHeight: 1.8, fontSize: "1rem", color: "#666" }}
          >
            {facilities}
          </Typography>
        </Box>
      )}

      {/* Address and Location Details */}
      <Grid container spacing={4} sx={{ mt: 5 }}>
        {address && (
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: "#eef2f7",
                padding: 3,
                borderRadius: 2,
                height: "100%",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontFamily: "'Poppins', sans-serif",
                  color: "#2c3e50",
                }}
              >
                Address
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Typography variant="body2" color="textSecondary">
                {address}
              </Typography>
            </Box>
          </Grid>
        )}
        {locationDetails && (
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: "#fff",
                padding: 3,
                borderRadius: 2,
                height: "100%",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontFamily: "'Poppins', sans-serif",
                  color: "#2c3e50",
                }}
              >
                Location
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Typography variant="body2" color="textSecondary">
                {locationDetails}
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>

      {/* Call-to-action Button */}
      <Box mt={5} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{
            backgroundColor: "#3498db",
            padding: "10px 20px",
            fontWeight: "bold",
            fontSize: "1.1rem",
            textTransform: "none",
          }}
        >
          Contact Hospital
        </Button>
      </Box>
    </Container>
  );
};

export default HospitalPageTemplate;
