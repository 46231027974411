import React, { useState } from "react";

export default function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  // Regular expressions for validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^[0-9]{10}$/; // Adjust the regex according to your phone number format requirements

  const validate = () => {
    let tempErrors = {};
    let isValid = true;

    if (formData.name.trim() === "") {
      tempErrors.name = "Name is required";
      isValid = false;
    }

    if (formData.email.trim() === "") {
      tempErrors.email = "Email is required";
      isValid = false;
    } else if (!emailRegex.test(formData.email)) {
      tempErrors.email = "Email is not valid";
      isValid = false;
    }

    if (formData.phoneNumber.trim() === "") {
      tempErrors.phoneNumber = "Phone Number is required";
      isValid = false;
    } else if (!phoneRegex.test(formData.phoneNumber)) {
      tempErrors.phoneNumber = "Phone Number is not valid";
      isValid = false;
    }

    if (formData.message.trim() === "") {
      tempErrors.message = "Message is required";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear the error message when the user starts typing
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      // Form is valid, proceed with submission (e.g., send data to the server)
      console.log("Form data:", formData);

      // Reset the form
      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
        message: "",
      });
      setErrors({});
    }
  };

  return (
    <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
      <form onSubmit={handleSubmit}>
        <div className="row">
          {/* Name Field */}
          <div className="col-lg-6">
            <label className="cs_input_label cs_heading_color">Name</label>
            <input
              type="text"
              className="cs_form_field"
              placeholder="Your Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <span className="error">{errors.name}</span>}
            <div className="cs_height_42 cs_height_xl_25" />
          </div>

          {/* Email Field */}
          <div className="col-lg-6">
            <label className="cs_input_label cs_heading_color">Email</label>
            <input
              type="email"
              className="cs_form_field"
              placeholder="example@gmail.com"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <span className="error">{errors.email}</span>}
            <div className="cs_height_42 cs_height_xl_25" />
          </div>

          {/* Phone Number Field */}
          <div className="col-lg-12">
            <label className="cs_input_label cs_heading_color">
              Phone Number
            </label>
            <input
              type="tel"
              className="cs_form_field"
              placeholder="Your Phone Number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
            {errors.phoneNumber && (
              <span className="error">{errors.phoneNumber}</span>
            )}
            <div className="cs_height_42 cs_height_xl_25" />
          </div>

          {/* Message Field */}
          <div className="col-lg-12">
            <label className="cs_input_label cs_heading_color">Message</label>
            <textarea
              cols={30}
              rows={10}
              className="cs_form_field"
              placeholder="Write your message..."
              name="message"
              value={formData.message}
              onChange={handleChange}
            />
            {errors.message && <span className="error">{errors.message}</span>}
            <div className="cs_height_42 cs_height_xl_25" />
          </div>

          {/* Submit Button */}
          <div className="col-lg-12">
            <div className="cs_height_18" />
            <button type="submit" className="cs_btn cs_style_1">
              <span>Submit</span>
              <i>
                <img src="/images/icons/arrow_white.svg" alt="Icon" />
                <img src="/images/icons/arrow_white.svg" alt="Icon" />
              </i>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
