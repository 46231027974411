import MasonryGallery from "../GalleryCollege/GalleryCollege";

import Section from "../Section";

export default function GalleryPage() {
  return (
    <>
      <Section topMd={190} topLg={145} topXl={105}>
        <MasonryGallery sectionTitle="Gallery" sectionTitleDown="Gallery" />
      </Section>
    </>
  );
}
