import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import HospitalPageTemplate from "../HospitalPageTemplate";
import { CircularProgress, Container } from "@mui/material";

const HospitalPage = () => {
  const { id } = useParams(); // Get the hospital name from the URL
  const [hospital, setHospital] = useState(null); // State to store the hospital data
  const [loading, setLoading] = useState(true);

  // Manually define all the necessary hospital data
  const manualHospitalData = {
    "jaypee-hospital": {
      name: "Jaypee Hospital",
      location: "Noida, India", // Use 'location' for hospital address
      description: `
      Jaypee Hospital is a flagship initiative from the Jaypee Group dedicated to providing quality healthcare. It is a tertiary care, multi-speciality hospital with 1200 beds capacity, operational in two phases.

      Highlights:
      - Holds NABH accreditation for quality healthcare standards.
      - Achieved the Achiever Emerging Multi-Speciality Hospital Award in 2018.
      - Ranked in the top 10 hospitals in North India by Times Health.
      - Successful in over 1350 organ transplants, with notable success in kidney and liver transplants.
      - Performed over 2000 knee surgeries and over 23,000 cancer procedures.
      `,
      image: `/images/hospitalImages/jaypee-hospital.webp`,
      specialServices: `
      Jaypee Hospital offers a range of services designed for both local and international patients. 

      **Pre-arrival Services**:
      - Appointment scheduling.
      - Virtual OPD & Teleconsultation services.
      - Visa assistance for international patients.

      **Arrival and Stay Services**:
      - Airport pick-up and drop-off.
      - Customized diet plans, Wi-Fi, laundry, prayer rooms.
      - Assistance with FRRO registration and accommodation.

      **Post-Discharge Services**:
      - Teleconsultations and in-house translators.
      - Assistance in getting opinions and further treatments.
      `,
      teamAndSpecialties: `
      **Team**:
      - Over 400 highly skilled doctors from the best institutes around the world.
      - Specializes in cardiac surgery, neurology, orthopaedics, oncology, plastic surgery, and reconstructive surgery.

      **Centers of Excellence**:
      - Specialties in kidney donor surgery, cardiac surgery, and orthopaedics.
      `,
      infrastructure: `
      - Spread over a 25-acre campus with state-of-the-art technology.
      - 150 critical care unit beds, 24 ICU beds, 325 regular ward beds, 20 dialysis units.
      - Advanced equipment including MRI (3.0 Tesla), PET-CT, Da Vinci Robot for surgeries.
      `,
      facilities: `
      - Private rooms, family accommodation, personal assistance, Wi-Fi, and safe in the room.
      - Cafeteria, laundry, parking, prayer rooms, dedicated smoking areas.
      `,
      address: `
      Jaypee Hospital Road, Goberdhanpur, Sector 128, Noida, 201304, India.
      `,
      locationDetails: ` // Renamed to 'locationDetails' to avoid conflict
      **Nearby Transportation**:
      - 35 km from Indira Gandhi International Airport, Delhi.
      - 5 km from Sector 148 Metro Station, part of the Aqua Line, Delhi Metro.
      - 20 km from Hazrat Nizamuddin Railway Station, New Delhi.
      `,
    },
    "county-general": {
      name: "County General",
      location: "456 Elm St, Springfield",
      description:
        "County General has been serving the community for over 50 years with quality medical care.",
      image: "/images/hospitalImages/county-general.png",
    },
    // Add more manually defined hospital data here
    "metro-heart-institute-with-multispecialty-hospital": {
      name: "Metro Heart Institute with Multispecialty Hospital",
      location: "Faridabad, India",
      description: `
  Metro Hospital, Faridabad was established in 2002, and the Metro Group has received Padma Vibhushan and Dr. B C Roy National Awardee Dr. Purshotam Lal (Chairman).
  
  Highlights:
  - Dr. Neeraj Jain is a renowned Cardiologist and Cardiac Surgeon, specializing in angioplasty.
  - Major departments include Cardiology, Neuro Sciences, Orthopedics, Onco-surgeries, Liver & Digestive Diseases, Mother & Child Care.
  - Functions as two units: Metro Heart Institute (90 beds) and Metro Multispecialty Hospital (307 beds).
  - Offers treatment in over 23 specialties.
  - Specializes in Preventive Cardiology, Interventional Cardiology, Electrophysiology, Cardiothoracic, and Vascular Surgery.
  `,
      image: `/images/hospitalImages/metro-hospital.webp`,
      specialServices: `
  Metro Hospital offers services designed for both local and international patients.
  
  **Arrival and Stay Services**:
  - Airport transfers, family accommodations, mobility accessible rooms.
  - Personalized diet plans, laundry, Wi-Fi, prayer rooms.
  
  **Post-Discharge Services**:
  - Teleconsultations, in-house translators, assistance with further treatments.
  `,
      teamAndSpecialties: `
  **Team**:
  - Highly qualified professionals with specialties across 18+ disciplines.
  - Cardiology team is led by Dr. Neeraj Jain.
  
  **Special Programs**:
  - Metro Coronary Screening allows angiography in 5 minutes at reduced costs.
  - Regular training programs for non-clinical staff to maintain nursing standards.
  `,
      infrastructure: `
  - Features 1.5 Tesla Siemens Essenza MRI, digital bone densitometry, and EBUS for diagnostics.
  - Intensive Coronary Care (45 beds), High Dependency Units with life support.
  - Facilities include painless labor and advanced CTG monitoring in labor rooms.
  - Ambulances equipped with ECG machines, defibrillators, and transport ventilators.
  `,
      facilities: `
  - Private rooms, mobility accessible rooms, family accommodation.
  - Laundry, cafeteria, spa, religious facilities, fitness center.
  - Business center services, concierge, nursery services.
  `,
      address: `
  Metro Heart Institute with Multispecialty, Sector 16 A, Faridabad, 121002, India.
  `,
      locationDetails: `
  **Nearby Transportation**:
  - 27 km from Indira Gandhi International Airport (1 hour 3 mins).
  - 3 km from the nearest metro station (9 mins).
  - Major malls, hotels, and shopping complexes nearby.
  `,
    },

    "blk-max-super-specialty-hospital-delhi": {
      name: "BLK-Max Super Speciality Hospital",
      location: "New Delhi, India",
      description: `
  BLK-Max Super Speciality Hospital is ranked among the top 10 multi-super specialty hospitals in Delhi NCR. The hospital provides world-class healthcare with state-of-the-art technology and highly skilled professionals, offering personalized treatment and holistic healing across various specialties.

  Highlights:
  - Accredited by Joint Commission International (JCI), NABH, and NABL for high-quality patient care.
  - Recognized with 45 awards, including ET Healthworld's Best Multi-Specialty Hospital and AHPI's "Quality Beyond Accreditation" award in 2020.
  - Notable for expertise in robotic surgeries, cancer care, and bone marrow transplants.
  - First private hospital in India to perform liver and heart transplants.
  - Offers pioneering technologies like Tomotherapy, Revolution Frontier CT Scan, and Signa Artist MRI.
  `,
      image: `/images/hospitalImages/blk-max-super-specialty-hospital-delhi.webp`,
      specialServices: `
  **International Patient Services**:
  - Serves over 20,000+ international patients annually.
  - Treated over 440,000 patients in IPD, conducted 130,000 surgeries, and provided 2.9 million OPD consultations.
  - Offers travel assistance, visa arrangements, language interpreters, and personalized services for overseas patients.
  - Provides FRRO registration assistance, currency exchange, car hire, and international cuisine options.
  `,
      teamAndSpecialties: `
  **Team**:
  - 1,500 trained staff and over 300 doctors across 34+ specialties.
  - Centers of Excellence include Cancer Centre, Bone Marrow Transplant Centre, Heart and Vascular Institute, Neurosciences Centre, and Spine and Sports Medicine Institute.

  **Special Programs**:
  - Employs 150 senior specialists, offering comprehensive care in medical, surgical, cardiac, paediatrics, neurology, and transplant programs.
  `,
      infrastructure: `
  - Spread across five acres with over 650 beds, including 162 critical care beds and 22 operation theatres.
  - Equipped with modular operation theatres, advanced diagnostic tools, and specialized birthing suites.
  - Outpatient services with 80 consultation rooms across two floors.
  - Dedicated ICU with HEPA filters, specialized liver and renal transplant centres, and 24x7 emergency care.
  `,
      facilities: `
  - Private rooms, mobility accessible rooms, family accommodations, and free Wi-Fi.
  - Business center services, laundry, dry cleaning, and religious facilities.
  - Cafeteria, nursery/nanny services, and concierge available.
  `,
      address: `
  Pusa Rd, Radha Soami Satsang, Rajendra Place, New Delhi, 110005, India.
  `,
      locationDetails: `
  **Nearby Transportation**:
  - 20 km from Indira Gandhi International Airport (25-30 minutes by car).
  - 1.3 km from Patel Nagar Metro Station (4 minutes by car).
  - 5.2 km from New Delhi Railway Station (10 minutes by car).
  `,
    },

    "fortis-escorts-hospital-delhi": {
      name: "Fortis Escorts Heart Institute",
      location: "New Delhi, India",
      description: `
  Established in 1988, Fortis Escorts Heart Institute (FEHI) is part of the Fortis Group and is globally recognized for its clinical excellence in cardiac care.
  
  Highlights:
  - Accredited by JCI since 2010, NABH for Blood Bank, Lab, and Nursing Excellence, and NABL for Laboratory Services in 2013.
  - Awarded numerous accolades for excellence, including Best Hospital in Cardiology by Times Healthcare Achievers Awards and ABP Healthcare Leadership.
  - The first hospital in India to conduct Transcatheter Aortic Valve Implantation (TAVI) and pioneer the TAVR Centre.
  - Holds a Guinness World Record for organizing the largest cholesterol level testing event.
  `,
      image: `/images/hospitalImages/fortis-escorts-hospital-delhi.webp`,
      specialServices: `
  **International Patient Services**:
  - Serves patients from 175+ countries with personalized care.
  - Provides services like visa assistance, invitation letters, airport pickup, accommodation, interpreters, and currency exchange.
  `,
      teamAndSpecialties: `
  **Team**:
  - FEHI specializes in Cardiac Bypass Surgery, Interventional Cardiology, Pediatric Cardiology, and more.
  - Recognized as a Centre of Excellence in Cardiology and home to some of the most accomplished surgeons in India.
  
  **Special Programs**:
  - Conducts over 173,000 Coronary Angiographies and 316,000 admissions annually.
  - Houses four Centres of Excellence: Heart Institute, Liver & Digestive Diseases Institute, Kidney & Urology Institute, and Bone & Joint Institute.
  `,
      infrastructure: `
  - 310 beds, including 159 critical care beds, and 9 advanced operation theatres.
  - Equipped with Cath Labs, 3D Echo, dual-head 6-slice SPECT scan, 3.0 Tesla MRI, and specialized birthing suites.
  - Offers state-of-the-art ICU services, advanced diagnostic labs, and remote monitoring via eICU.
  `,
      facilities: `
  - Private rooms, mobility accessible rooms, family accommodation, and free Wi-Fi.
  - Business center services, laundry, dedicated smoking areas, and concierge services.
  - Cafeteria, nursery/nanny services, and personal assistance available.
  `,
      address: `
  Okhla road, Sukhdev Vihar Metro Station, New Delhi, 110025, India.
  `,
      locationDetails: `
  **Nearby Transportation**:
  - 16 km from Indira Gandhi International Airport (25-30 minutes by car).
  - 2 km from Kashmiri Gate Metro Station (5-10 minutes by car).
  - 2.5 km from Kashmiri Gate Railway Station (5-10 minutes by car).
  `,
    },

    "aakash-healthcare-super-specialty-hospital": {
      name: "Aakash Healthcare Super Speciality Hospital",
      location: "Dwarka, New Delhi, India",
      description: `
  Aakash Healthcare, established in 2011 under the brand name 'Bone and Joint Clinic,' is a leading center of excellence in Orthopaedics and Joint Replacement.
  
  Highlights:
  - Accredited by NABH and ISO 9001:2000 for high standards of medical care.
  - Renowned for its state-of-the-art facilities in orthopaedics, particularly for knee and joint issues.
  - Provides advanced robotic surgeries and top-quality joint implants for precision and global standards.
  - Located in South Delhi, it is one of the top hospitals for knee transplants and joint replacement surgery.
  `,
      image: `/images/hospitalImages/aakash-healthcare-super-specialty-hospital.webp`,
      specialServices: `
  **International Patient Services**:
  - Offers comprehensive packages, visa and travel assistance, dedicated patient managers, and accommodation.
  - Provides translators for language barriers and customized health check packages for international patients.
  `,
      teamAndSpecialties: `
  **Team**:
  - Equipped with medical and paramedical staff specialized in emergency and orthopedic surgeries.
  - Experts in Knee Replacement Surgery, Robotic Joint Replacement, Sports Medicine, Pediatric Orthopaedics, and Pain Management.
  
  **Special Programs**:
  - Specializes in procedures like Robotic Joint Replacement, Arthroscopy, Total Hip Replacement, and advanced Orthopaedic surgeries.
  - Expands services to include Dentistry, Dermatology, ENT, General Surgery, and Internal Medicine.
  `,
      infrastructure: `
  - Equipped with advanced orthopedic operating theatres, robotic surgery equipment, and smart digital systems.
  - Offers 24-hour services like ultrasound, X-ray, ECG, and TMT. Equipped with an in-built oxygen and suction pipeline system.
  - Features 15 Dialysis beds, 70 Critical Care beds, and a 24x7 Trauma & Emergency Center.
  - Provides comprehensive services including Ambulance, Home Healthcare, and Aakash Laboratory and Pharmacy.
  `,
      facilities: `
  - Private rooms, mobility accessible rooms, family accommodations, and free Wi-Fi.
  - Business center services, laundry, religious facilities, and concierge services.
  - Cafeteria, nursery/nanny services, personal assistance, and parking available.
  `,
      address: `
  Hospital Plot, Road No. 201, Dwarka Sector-3, Dwarka, New Delhi, 110075, India.
  `,
      locationDetails: `
  **Nearby Transportation**:
  - 10 km from Indira Gandhi International Airport (20 minutes by car).
  - 2 km from Dwarka Sector 21 Metro Station (5 minutes by car or 10-15 minutes by walking).
  - 30 km from New Delhi Railway Station (45 minutes to 1 hour by car).
  `,
    },
  };

  useEffect(() => {
    // Find the hospital in the manually provided data
    const foundHospital = manualHospitalData[id];

    if (foundHospital) {
      setHospital(foundHospital);
    } else {
      console.error("No hospital data found for this name");
    }

    setLoading(false);

    // Disable the exhaustive-deps warning for manualHospitalData
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]); // Only id (name from URL) should be in the dependency array

  if (loading) {
    return (
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  if (!hospital) {
    return <div>Hospital not found</div>; // Handle the case where the hospital is not found
  }

  return (
    <HospitalPageTemplate
      image={hospital.image}
      name={hospital.name}
      location={hospital.location}
      description={hospital.description}
      specialServices={hospital.specialServices}
      teamAndSpecialties={hospital.teamAndSpecialties}
      infrastructure={hospital.infrastructure}
      facilities={hospital.facilities}
      address={hospital.address}
      locationDetails={hospital.locationDetails} // Use the new key for nearby location details
    />
  );
};

export default HospitalPage;
