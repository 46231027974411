import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Spacing from "../../Spacing";
import { db } from "../../../firebase";
import { collection, getDocs } from "firebase/firestore";
import SectionHeading from "../../SectionHeading";
import {
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";

export default function BrandsSectionCustom({ sectionTitle }) {
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const departmentsRef = collection(db, "departments");
        const querySnapshot = await getDocs(departmentsRef);
        const fetchedDepartments = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Predefined order of department names based on the image
        const departmentOrder = [
          "neuro surgery & neuro spine",
          "cardiology",
          "urology & kidney transplant",
          "nephrology & kidney transplantation",
          "gastroenterology",
          "liver transplant & hpb surgery",
          "vascular interventions and surgery",
          "medical oncology",
        ];

        // Sort departments according to the predefined order
        const sortedDepartments = departmentOrder
          .map((name) =>
            fetchedDepartments.find((d) => d.departmentName === name)
          )
          .filter(Boolean); // Remove undefined entries if a department doesn't exist

        setDepartments(sortedDepartments); // Use the sorted departments
        setLoading(false);
      } catch (error) {
        console.error("Error fetching departments:", error);
        setLoading(false);
      }
    };

    fetchDepartments();
  }, []); // No need for departmentOrder as a dependency

  // Function to generate icon URL based on department name
  const getIconUrl = (departmentName) => {
    if (!departmentName) {
      return "/images/default-icon.png";
    }
    const formattedName = departmentName
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/&/g, "and")
      .replace(/[^a-z0-9-]/g, "");

    const iconPath = `/images/departmentIcons/${formattedName}.png`;
    return iconPath;
  };

  if (loading) {
    return (
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 8, mb: 8 }}>
      {sectionTitle && (
        <>
          <SectionHeading title={sectionTitle} center />
          <Spacing md="60" />
        </>
      )}

      <Grid container spacing={4} justifyContent="center">
        {departments.map((department) => (
          <Grid item xs={12} sm={6} md={3} key={department.id}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                borderRadius: 2,
                boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                padding: "1rem",
                backgroundColor: "#fff",
                cursor: "pointer",
              }}
              onClick={() =>
                navigate(`/doctors?department=${department.departmentName}`)
              }
            >
              <CardMedia
                component="img"
                image={
                  getIconUrl(department.departmentName) ||
                  "/images/departmentIcons/default.png"
                }
                alt={department.departmentName}
                sx={{
                  height: "64px",
                  width: "64px",
                  margin: "0 auto",
                  objectFit: "contain",
                }}
              />
              <CardContent
                sx={{
                  textAlign: "center",
                  flexGrow: 1,
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontWeight: "600",
                    fontSize: "1.2rem",
                    color: "#333",
                  }}
                >
                  {department.departmentName}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* View More Departments button */}
      <Container sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/department")}
        >
          View More Departments
        </Button>
      </Container>
    </Container>
  );
}
