import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";

const Departments = () => {
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDepartments = async () => {
      const departmentsSnapshot = await getDocs(collection(db, "departments"));
      setDepartments(
        departmentsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
      setLoading(false);
    };

    fetchDepartments();
  }, []);

  if (loading) {
    return (
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  // Function to generate the icon URL based on the department name
  const getIconUrl = (departmentName) => {
    const formattedName = departmentName
      .toLowerCase()
      .replace(/ /g, "-") // Replace spaces with hyphens
      .replace(/&/g, "and") // Replace '&' with 'and'
      .replace(/[^a-z0-9-]/g, ""); // Remove other special characters

    const iconPath = `/images/departmentIcons/${formattedName}.png`;
    return iconPath;
  };

  return (
    <Container sx={{ mt: 8, mb: 8 }}>
      <Typography
        variant="h4"
        component="h1"
        align="center"
        gutterBottom
        sx={{
          mb: 5,
          fontWeight: "bold",
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        Our Specialities
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {departments.map((department) => (
          <Grid item xs={12} sm={6} md={3} key={department.id}>
            <Link
              to={`/doctors?department=${encodeURIComponent(
                department.departmentName
              )}`}
              style={{ textDecoration: "none" }}
            >
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  borderRadius: 2,
                  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                  padding: "1rem",
                  backgroundColor: "#fff",
                }}
              >
                <CardMedia
                  component="img"
                  image={
                    getIconUrl(department.departmentName) ||
                    "/images/departmentIcons/default.png"
                  }
                  alt={department.departmentName}
                  sx={{
                    height: "64px",
                    width: "64px",
                    margin: "0 auto",
                    objectFit: "contain",
                  }}
                />
                <CardContent
                  sx={{
                    textAlign: "center",
                    flexGrow: 1,
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      fontWeight: "600",
                      fontSize: "1.2rem",
                      color: "#333",
                    }}
                  >
                    {department.departmentName}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Departments;
