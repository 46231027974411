// src/components/Hospitals.jsx
import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";

const Hospitals = () => {
  const [hospitals, setHospitals] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHospitals = async () => {
      const hospitalsSnapshot = await getDocs(collection(db, "hospitals"));
      setHospitals(
        hospitalsSnapshot.docs.map((doc) => ({
          id: doc.id, // Only the ID (or name) is fetched from Firebase
          ...doc.data(),
        }))
      );
      setLoading(false);
    };

    fetchHospitals();
  }, []);

  if (loading) {
    return (
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  // Function to generate the image URL based on the hospital name
  const getImageUrl = (hospitalName) => {
    const formattedName = hospitalName
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/&/g, "and")
      .replace(/[^a-z0-9-]/g, "");

    return `/images/hospitalImages/${formattedName}.webp`;
  };

  return (
    <Container sx={{ mt: 8, mb: 8 }}>
      <Typography
        variant="h4"
        component="h1"
        align="center"
        gutterBottom
        sx={{
          mb: 5,
          fontWeight: "bold",
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        Our Hospitals
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {hospitals.map((hospital) => (
          <Grid item xs={12} sm={6} md={3} key={hospital.id}>
            <Link
              to={`/hospital/${hospital.hospitalName
                .toLowerCase()
                .replace(/ /g, "-")
                .replace(/&/g, "and")
                .replace(/[^a-z0-9-]/g, "")}`} // Use hospital name instead of ID
              style={{ textDecoration: "none" }}
            >
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  borderRadius: 2,
                  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                  padding: "1rem",
                  backgroundColor: "#fff",
                }}
              >
                <CardMedia
                  component="img"
                  image={
                    getImageUrl(hospital.hospitalName) ||
                    "/images/hospitalImages/default.jpg"
                  }
                  alt={hospital.hospitalName}
                  sx={{
                    height: "150px",
                    width: "100%",
                    margin: "0 auto",
                    objectFit: "contain",
                  }}
                />
                <CardContent
                  sx={{
                    textAlign: "center",
                    flexGrow: 1,
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      fontWeight: "600",
                      fontSize: "1.2rem",
                      color: "#333",
                    }}
                  >
                    {hospital.hospitalName}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Hospitals;
