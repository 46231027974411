import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useNavigate } from "react-router-dom";
import SectionHeading from "../SectionHeading"; // Adjust the path as needed
import Spacing from "../Spacing"; // Adjust the path as needed
import styles from "./GalleryPreview.module.css"; // Import the module.css

const GalleryPreview = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const navigate = useNavigate();

  const images = [
    "/images/px/23.webp",
    "/images/px/36.webp",
    "/images/px/3.webp",
    "/images/px/12.webp",
  ];

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const handleViewMore = () => {
    navigate("/gallery-page");
  };

  return (
    <div className={styles.container}>
      {/* Gallery Heading */}
      <div className={styles.headingContainer}>
        <SectionHeading title="Gallery" />
      </div>
      <Spacing md="85" lg="70" xl="50" /> {/* Adding vertical spacing */}
      {/* Grid for 4 Cards */}
      <div className={styles.galleryGrid}>
        {images.map((image, index) => (
          <div key={index} className="relative group">
            <img
              src={image}
              alt={`Gallery item ${index}`}
              className={styles.galleryImg}
              onClick={() => openLightbox(index)}
            />
          </div>
        ))}
      </div>
      {/* View More Button */}
      <div className={styles.buttonContainer}>
        <button
          className={`${styles.button} ${styles.viewMoreButton}`}
          onClick={handleViewMore}
        >
          View More
        </button>
      </div>
      {/* Lightbox Modal */}
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
};

export default GalleryPreview;
