import React, { useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const MasonryGallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  // Array of local images from your 'public/images/px' directory
  const images = [
    "/images/px/1.webp",
    "/images/px/2.webp",
    "/images/px/3.webp",
    "/images/px/4.webp",
    "/images/px/5.webp",
    "/images/px/6.webp",
    "/images/px/7.webp",
    "/images/px/8.webp",
    "/images/px/9.webp",
    "/images/px/10.webp",
    "/images/px/11.webp",
    "/images/px/12.webp",
    "/images/px/13.webp",
    "/images/px/14.webp",
    "/images/px/15.webp",
    "/images/px/16.webp",
    "/images/px/17.webp",
    "/images/px/18.webp",
    "/images/px/19.webp",
    "/images/px/20.webp",
    "/images/px/21.webp",
    "/images/px/22.webp",
    "/images/px/23.webp",
    "/images/px/24.webp",
    "/images/px/25.webp",
    "/images/px/26.webp",
    "/images/px/27.webp",
    "/images/px/28.webp",
    "/images/px/29.webp",
    "/images/px/30.webp",
    "/images/px/31.webp",
    "/images/px/32.webp",
    "/images/px/33.webp",
    "/images/px/34.webp",
    "/images/px/35.webp",
    "/images/px/36.webp",
    "/images/px/37.webp",
    "/images/px/38.webp",
    "/images/px/39.webp",
    "/images/px/40.webp",
    "/images/px/41.webp",
    "/images/px/42.webp",
    "/images/px/43.webp",
    "/images/px/44.webp",
    "/images/px/45.webp",
    "/images/px/46.webp",
    "/images/px/47.webp",
    "/images/px/48.webp",
    "/images/px/49.webp",
    "/images/px/50.webp",
    "/images/px/51.webp",
    "/images/px/52.webp",

    // add more images as needed
  ];

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <div className="container mx-auto">
      {/* Responsive Masonry Layout */}
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1200: 4 }}
      >
        <Masonry gutter="20px">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Gallery item ${index}`}
              className="w-full h-auto rounded-lg cursor-pointer transition-transform transform hover:scale-105"
              onClick={() => openLightbox(index)}
            />
          ))}
        </Masonry>
      </ResponsiveMasonry>

      {/* Lightbox Modal */}
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
};

export default MasonryGallery;
