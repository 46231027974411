import React from "react";

export default function Feature({ iconSrc, title, subTitle }) {
  return (
    <div className="cs_feature cs_style_1 cs_shadow_1 cs_radius_25 cs_white_bg">
      <h2 className="cs_feature_title cs_semibold cs_fs_40 cs_center">
        <span className="cs_feature_icon cs_accent_bg cs_center rounded-circle">
          <img src={iconSrc} alt="Icon" />
        </span>
        <span>{title}</span>
      </h2>
      <p className="m-0 text-center">{subTitle}</p>

      {/* Connect Us Button */}
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <a
          href="https://api.whatsapp.com/send?phone=919454674622"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: "inline-block",
            padding: "10px 20px",
            backgroundColor: "#307BC4",
            color: "#fff",
            borderRadius: "10px",
            fontWeight: "bold",
            fontSize: "16px",
            textDecoration: "none",
          }}
        >
          Connect Us
        </a>
      </div>
    </div>
  );
}
