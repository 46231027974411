import React from "react";
import IconBoxStyle11 from "../../IconBox/IconBoxStyle11";
import Spacing from "../../Spacing";

export default function ContactInfoSection({ sectionTitle }) {
  return (
    <div className="container">
      <h2 className="cs_fs_72 mb-0 text-center">{sectionTitle}</h2>
      <Spacing md="70" lg="50" />

      {/* Grid Layout for Equal Width and Height Cards */}
      <div
        className="grid-container"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)", // Set a fixed number of columns
          gap: "20px",
        }}
      >
        {/* Phone Section */}
        <div
          className="grid-item"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#e0f7fa", // Light blue background
            padding: "20px",
            borderRadius: "15px",
            textAlign: "center",
            minHeight: "200px", // Ensure equal height
          }}
        >
          <IconBoxStyle11
            title="Phone"
            subTitle={
              <>
                <a
                  href="tel:+919454674622"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  +91-9454674622
                </a>
                <br />
                <a
                  href="tel:+911145871974"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  01145871974
                </a>
              </>
            }
            iconSrc="/images/contact/icon_1.svg"
          />
        </div>

        {/* Email Section */}
        <div
          className="grid-item"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#e0f7fa", // Light blue background
            padding: "20px",
            borderRadius: "15px",
            textAlign: "center",
            minHeight: "200px", // Ensure equal height
          }}
        >
          <IconBoxStyle11
            title="Email"
            subTitle={
              <>
                <a
                  href="mailto:helalhealthcare.co@gmail.com"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  helalhealthcare.co@gmail.com
                </a>
                <br />
                <a
                  href="mailto:info@helalhealthcare.com"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  info@helalhealthcare.com
                </a>
              </>
            }
            iconSrc="/images/contact/icon_2.svg"
          />
        </div>

        {/* Location Section */}
        <div
          className="grid-item"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#e0f7fa", // Light blue background
            padding: "20px",
            borderRadius: "15px",
            textAlign: "center",
            minHeight: "200px", // Ensure equal height
          }}
        >
          <IconBoxStyle11
            title="Location"
            subTitle={
              <a
                href="https://www.google.com/maps?q=R-42,+3rd+Floor,+Opp.+Hira+Sweet,+Main+Vikas+Marg+Shakarpur,+Near+Metro+Pillar+No.46,+Delhi-110092"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                R-42, 3rd Floor, Opp. Hira Sweet, Main Vikas Marg Shakarpur,
                Near Metro Pillar No.46, Delhi-110092
              </a>
            }
            iconSrc="/images/contact/icon_3.svg"
          />
        </div>
      </div>

      <Spacing md="35" />

      {/* Start Google Map */}
      <div className="cs_map">
        <iframe
          id="map"
          title="Google Map"
          src="https://www.google.com/maps?q=R-42,+3rd+Floor,+Opp.+Hira+Sweet,+Main+Vikas+Marg+Shakarpur,+Near+Metro+Pillar+No.46,+Delhi-110092&output=embed"
          allowFullScreen
          style={{ width: "100%", height: "400px", border: "0" }}
        />
      </div>
      {/* End Google Map */}
    </div>
  );
}
