/* eslint-disable react/prop-types */
import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Typography, Button } from "@mui/material";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";

const TopDoctors = () => {
  const sliderRef = useRef(null);
  const [doctors, setDoctors] = useState([]);
  const [isAnimated, setIsAnimated] = useState(false);
  const navigate = useNavigate();

  // Fetch doctors data from Firestore
  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "doctors"));
        const fetchedDoctors = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDoctors(fetchedDoctors);
      } catch (error) {
        console.error("Error fetching doctors:", error);
      }
    };
    fetchDoctors();
  }, []);

  const settings = {
    arrows: false,
    centerMode: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Animation trigger using IntersectionObserver
  useEffect(() => {
    const currentSliderRef = sliderRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsAnimated(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (currentSliderRef) {
      observer.observe(currentSliderRef);
    }

    return () => {
      if (currentSliderRef) {
        observer.unobserve(currentSliderRef);
      }
    };
  }, []);

  // Function to capitalize and bold the first letter of each word in the doctor's name
  const renderDoctorName = (name) => {
    if (!name) return "";

    let processedName = name.trim();
    let prefix = "";

    // Check for 'Dr.' prefix
    if (processedName.toLowerCase().startsWith("dr. ")) {
      prefix = "Dr. ";
      processedName = processedName.substring(4).trim(); // Remove 'Dr. ' prefix
    } else if (processedName.toLowerCase().startsWith("dr.")) {
      prefix = "Dr.";
      processedName = processedName.substring(3).trim(); // Remove 'Dr.' prefix
    } else {
      prefix = "Dr. ";
    }

    // Capitalize each word and bold the first letter
    const words = processedName.split(" ");

    const renderedName = words.map((word, index) => {
      const firstLetter = word.charAt(0).toUpperCase();
      const rest = word.slice(1);
      return (
        <span key={index}>
          <span style={{ fontWeight: "bold" }}>{firstLetter}</span>
          {rest}{" "}
        </span>
      );
    });

    return (
      <span>
        {prefix}
        {renderedName}
      </span>
    );
  };

  // Function to capitalize the first letter of each word in the department name
  const capitalizeDepartment = (department) => {
    if (!department) return "Department not provided";

    return department
      .split(" ")
      .map((word) => {
        const firstLetter = word.charAt(0).toUpperCase();
        const rest = word.slice(1).toLowerCase();
        return firstLetter + rest;
      })
      .join(" ");
  };

  return (
    <Box
      ref={sliderRef}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "2rem 0",
        width: "100%",
      }}
    >
      {isAnimated && (
        <>
          <SectionHeading title="Top Doctors" />
          <Spacing md="85" lg="70" xl="50" />
        </>
      )}
      <Box sx={{ width: "90%", maxWidth: "1200px" }}>
        <Slider {...settings}>
          {doctors.map((doctor) => (
            <Box key={doctor.id} sx={{ padding: "10px" }}>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  height: "400px",
                  borderRadius: "15px",
                  boxShadow: "0 10px 20px rgba(0, 0, 0, 0.3)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "20px",
                  textAlign: "center",
                }}
              >
                <Box
                  component="img"
                  src={doctor.image || "/images/doctors/default.png"}
                  alt={doctor.doctorName}
                  sx={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    marginBottom: "1rem",
                  }}
                />
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  {renderDoctorName(doctor.doctorName)}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    mt: 1,
                  }}
                >
                  {capitalizeDepartment(doctor.department)}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mt: 1 }}
                >
                  Experience: {doctor.experience} years
                </Typography>
                <Box sx={{ mt: 2, display: "flex", gap: 1 }}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#25D366",
                      color: "#fff",
                      flex: 1,
                    }}
                    onClick={() =>
                      window.open(
                        `https://api.whatsapp.com/send?phone=919454674622&text=Hello, I would like to book an appointment with ${doctor.doctorName}`,
                        "_blank"
                      )
                    }
                  >
                    Book Appointment
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ flex: 1 }}
                    onClick={() => navigate(`/doctor/${doctor.id}`)}
                  >
                    Read More
                  </Button>
                </Box>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
      {/* View More Doctors Button */}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/doctors")}
          sx={{
            padding: "0.7rem 2rem",
            borderRadius: "30px",
            fontWeight: "bold",
            fontSize: "1rem",
          }}
        >
          View More Doctors
        </Button>
      </Box>
    </Box>
  );
};

export default TopDoctors;
