import React, { useEffect, useState, useRef } from "react";
import WaterWave from "react-water-wave";

export default function HeroStyle6({
  title,
  subTitle,
  bgUrl,
  imgUrl,
  funfactList,
}) {
  const [counters, setCounters] = useState(funfactList.map(() => 0)); // Initialize counters for each funfact
  const [isVisible, setIsVisible] = useState(false);
  const counterRef = useRef(null); // Ref for the counter section

  useEffect(() => {
    const duration = 2000; // Duration for all counters to finish (in milliseconds)
    const steps = 100; // Number of steps for each counter
    const intervalTime = duration / steps;

    let intervals = [];

    if (isVisible) {
      intervals = funfactList.map((item, index) => {
        const targetNumber = parseInt(item.number.replace(/\D/g, "")); // Remove non-digits
        const increment = targetNumber / steps;

        return setInterval(() => {
          setCounters((prevCounters) => {
            const newCounters = [...prevCounters];
            if (newCounters[index] < targetNumber) {
              newCounters[index] += increment;
            } else {
              newCounters[index] = targetNumber;
              clearInterval(intervals[index]);
            }
            return newCounters;
          });
        }, intervalTime);
      });
    }

    return () => {
      intervals.forEach((interval) => clearInterval(interval));
    };
  }, [isVisible, funfactList]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true); // Trigger the counter when visible
            observer.disconnect(); // Stop observing after it's visible
          }
        });
      },
      {
        threshold: 0.1, // Trigger when 10% of the section is visible
      }
    );

    const currentRef = counterRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) observer.disconnect();
    };
  }, []);

  return (
    <WaterWave className="cs_hero cs_style_2 " imageUrl={bgUrl}>
      {() => (
        <>
          <div className="container">
            <div className="cs_hero_text">
              <h1 className="cs_hero_title cs_white_color cs_fs_84">{title}</h1>
              <div className="cs_hero_text_in">
                <p
                  className="cs_hero_subtitle cs_white_color"
                  style={{ fontSize: "1.3rem", fontWeight: "500" }}
                >
                  {subTitle}
                </p>
              </div>
            </div>
            <img src={imgUrl} alt="Hero" className="cs_hero_patents" />
          </div>
          <div className="container">
            <div className="cs_hero_bottom">
              <div className="cs_hero_bottom_left" style={{ display: "none" }}>
                <div className="cs_hero_btn_wrap"></div>
              </div>
              <div
                className="cs_hero_bottom_right"
                style={{ width: "100%" }}
                ref={counterRef} // Attach ref here
              >
                {funfactList?.length > 0 && (
                  <div
                    className="cs_hero_funfact text-center"
                    style={{ maxWidth: "100%" }}
                  >
                    {funfactList?.map((item, index) => (
                      <div className="cs_hero_funfact_col" key={index}>
                        <h3 className="cs_white_color cs_fs_72">
                          {/* Display the incrementing counter */}
                          {Math.floor(counters[index])}
                          {/* Keep any non-digit characters after the number */}
                          {item.number.replace(/\d/g, "")}
                        </h3>
                        <p className="cs_white_color mb-0">{item.title}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </WaterWave>
  );
}
